import { useShops } from '../../../hooks/use-shops';
import { CATEGORY_RIGHT_SEARCH_BY, ELEMENT_RIGHT_CATEGORIES, LOCATION_RIGHT_PANEL, useTracking } from '../../../hooks/use-tracking';
import { Essential } from '../../../types/visiomap';
import { List } from '../list-components/list';

type Props = {
    visioWebEssential: Essential | null;
    onClick: (id?: string) => void;
};

export const Categories = ({ visioWebEssential, onClick }: Props) => {
    const { getCategoryList } = useShops(visioWebEssential);
    const { trackSelectSearch } = useTracking();

    return (
        <List
            items={getCategoryList()}
            onClick={(floorplanId?: string, itemName?: string) => {
                onClick(floorplanId);
                trackSelectSearch(CATEGORY_RIGHT_SEARCH_BY, ELEMENT_RIGHT_CATEGORIES, itemName || '', LOCATION_RIGHT_PANEL);
            }}
        />
    );
};
