import QRCode from 'react-qr-code';
import { OfferType } from '../../services/meeting-place/offers';
import { OccasionType } from '../../services/meeting-place/occasions';
import { OfferSmall } from './offer-small';
import { EventSmall } from './event-small';
import { useLocalization } from '../../localization/use-localization';
import { NavLink } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { MeetingPlaceContext } from '../../contexts/meeting-place-provider';
import clsx from 'clsx';
import { CATEGORY_EVENTS, CATEGORY_OFFERS, LOCATION_FRONT_PAGE, useTracking } from '../../hooks/use-tracking';

type OEProps = {
    offers: OfferType[];
    events: OccasionType[];
    showEvents: boolean | null | undefined;
    showOffers: boolean | null | undefined;
};

const NUMER_OF_ITEMS_VERTICAL_2K_PLUS = 6;
const NUMER_OF_ITEMS_VERTICAL_1080 = 4;
const NUMER_OF_ITEMS_HORIZONTAL = 4;

const OffersEventsSection = (props: OEProps) => {
    const { events, offers, showEvents, showOffers } = props;
    const { getTranslation } = useLocalization();
    const { libraryUrlSubdirectoryCollection, locale, domain } = useContext(MeetingPlaceContext);
    const [offerQRUrl, setOfferQRUrl] = useState('');
    const [eventQRUrl, setEventQRUrl] = useState('');
    const [qrSize, setQrSize] = useState(80);
    const { trackSeeAll } = useTracking();
    const { is2k, isFullhd, isPortrait, is4k } = useContext(MeetingPlaceContext);
    const itemCount = isPortrait
        ? is4k || is2k
            ? NUMER_OF_ITEMS_VERTICAL_2K_PLUS - 1
            : NUMER_OF_ITEMS_VERTICAL_1080 - 1
        : NUMER_OF_ITEMS_HORIZONTAL - 1;

    useEffect(() => {
        libraryUrlSubdirectoryCollection.items.forEach(el => {
            if (el.contentTypes.indexOf('ContentfulMeetingPlacePageOfferItem') >= 0) {
                setOfferQRUrl(`${domain}/${locale}/${el.urlSubdirectory}`);
            }
            if (el.contentTypes.indexOf('ContentfulMeetingPlacePageEventItem') >= 0) {
                setEventQRUrl(`${domain}/${locale}/${el.urlSubdirectory}`);
            }
        });
        handleResize();
    }, []);

    const handleResize = () => {
        if (isPortrait) {
        } else {
            if (window.innerHeight > 900 && window.innerHeight <= 1080) {
                setQrSize(90);
            } else if (window.innerHeight > 1080 && window.innerHeight <= 1620) {
                setQrSize(100);
            } else if (window.innerHeight > 1620 && window.innerHeight <= 2000) {
                setQrSize(150);
            } else if (window.innerHeight > 2000) {
                setQrSize(180);
            } else {
                setQrSize(80);
            }
        }

        if (isPortrait) {
            if (isFullhd && !is2k) {
                setQrSize(75);
            } else if (is2k && !is4k) {
                setQrSize(100);
            } else if (is4k) {
                setQrSize(150);
            } else {
                setQrSize(50);
            }
        } else {
            if (window.innerHeight > 1080 && window.innerHeight < 1440) {
                setQrSize(80);
            } else if (window.innerHeight >= 1440 && window.innerHeight <= 2000) {
                setQrSize(100);
            } else if (window.innerHeight > 2000) {
                setQrSize(150);
            } else {
                setQrSize(70);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getCss = (type: string): string => {
        switch (type) {
            case 'header-box':
                return clsx('flex flex-row rounded bg-[#F7F4EF]', {
                    'h-[15%] gap-8': !isPortrait,
                    'h-[14%] gap-4': isPortrait,
                });
            case 'offer-event-title-box':
                return clsx('w-[60%] flex-shrink-0 flex-row ', {
                    'p-[3rem]': is4k && isPortrait,
                    'p-[2rem]': is2k && !is4k && isPortrait,
                    'p-[1.5rem]': isFullhd && !is2k && isPortrait,
                    'p-5': !isPortrait,
                });
            case 'offer-event-title':
                return clsx(
                    ' font-bold',
                    { 'mb-[0.7rem] text-[1.5rem]': isFullhd && !is2k && isPortrait },
                    { 'mb-[1rem] text-[2rem]': is2k && !is4k && isPortrait },
                    { 'mb-[2rem] text-[3rem]': is4k && isPortrait },
                    { 'mb-3 text-xl': !isPortrait }
                );
            case 'send-qr-code-text':
                return clsx({
                    'text-[0.8rem]': isFullhd && !is2k && isPortrait,
                    'text-[1.2rem]': is2k && !is4k && isPortrait,
                    'text-[1.8rem]': is4k && isPortrait,
                });
            case 'container-qr-code':
                return clsx('flex h-full flex-shrink-0 items-center justify-center', {
                    'w-[30%]': !isPortrait,
                });
            case 'see-all-events-offers':
                return clsx('mt-3 rounded bg-[#EAE3DA] p-[2%] px-4', {
                    'text-[1.2rem]': isFullhd && !is2k && isPortrait,
                    'text-[1.7rem]': is2k && !is4k && isPortrait,
                    'text-[2.2rem]': is4k && isPortrait,
                });
        }
        return '';
    };

    return (
        <div className={clsx('flex h-full w-full flex-row', isPortrait && isFullhd && !is2k ? 'gap-[1rem]' : ' gap-5')}>
            {showEvents ? (
                <div className="h-full w-full flex-[1] flex-col gap-3">
                    <div className={getCss('header-box')}>
                        <div className={getCss('offer-event-title-box')}>
                            <div className={getCss('offer-event-title')}>{getTranslation('Events')}</div>
                            <div className={getCss('send-qr-code-text')}>{getTranslation('ScanQrCodeEvents')}</div>
                        </div>
                        <div className={getCss('container-qr-code')}>
                            <QRCode value={eventQRUrl} size={qrSize} color="#F7F4EF" />
                        </div>
                    </div>
                    <div
                        className={clsx('mt-4 grid gap-4', {
                            'grid-cols-2 grid-rows-1': events.length < 3,
                            'grid-cols-2 grid-rows-2': events.length >= 3,
                        })}
                    >
                        {events.map((el, i) => {
                            if (i > itemCount) {
                                return null;
                            }
                            return (
                                <div key={i} className="max-h-full rounded bg-[#F7F4EF]">
                                    <EventSmall event={el as OccasionType} classNames="h-auto max-w-full" key={i} size="xs" />
                                </div>
                            );
                        })}
                    </div>
                    <div className="mt-2 flex items-center justify-start">
                        <NavLink
                            to="/events"
                            className={getCss('see-all-events-offers')}
                            data-tracking="open-offers-btn"
                            onClick={() => {
                                trackSeeAll(CATEGORY_EVENTS, LOCATION_FRONT_PAGE);
                            }}
                        >
                            <div>{getTranslation('seeAllEvents')}</div>
                        </NavLink>
                    </div>
                </div>
            ) : null}
            {showOffers ? (
                <div className="h-full w-full flex-[1] flex-col gap-3">
                    <div className={getCss('header-box')}>
                        <div className={getCss('offer-event-title-box')}>
                            <div className={getCss('offer-event-title')}>{getTranslation('Offers')}</div>
                            <div className={getCss('send-qr-code-text')}>{getTranslation('ScanQrCodeOffers')}</div>
                        </div>
                        <div className={getCss('container-qr-code')}>
                            <QRCode value={offerQRUrl} size={qrSize} color="#F7F4EF" />
                        </div>
                    </div>
                    <div
                        className={clsx('mt-4 grid gap-4', {
                            'grid-cols-2 grid-rows-1': events.length < 3,
                            'grid-cols-2 grid-rows-2': events.length >= 3,
                        })}
                    >
                        {offers.map((el, i) => {
                            if (i > itemCount) {
                                return null;
                            }
                            return (
                                <div key={i} className="max-h-full rounded ">
                                    <OfferSmall key={el.name} offer={el} />
                                </div>
                            );
                        })}
                    </div>
                    <div className="mt-2 flex items-center justify-start">
                        <NavLink
                            to="/offers"
                            className={getCss('see-all-events-offers')}
                            data-tracking="open-offers-btn"
                            onClick={() => {
                                trackSeeAll(CATEGORY_OFFERS, LOCATION_FRONT_PAGE);
                            }}
                        >
                            <div>{getTranslation('seeAllOffers')}</div>
                        </NavLink>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default OffersEventsSection;
