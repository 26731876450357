import { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { ShopType } from '../../../services/meeting-place/shops';
import { OpeningHours } from '../right/opening-hours';
import { useFindPlace } from '../../../hooks/use-find-place';
import { Toggle } from '../../../assets/svg/toggle';
import { useLocalization } from '../../../localization/use-localization';
import { CmsText } from '../../cms-text';
import { OpenToday } from '../right/open-today';
import { getRichTextRendererOptions } from '../../../helpers/rich-text-renderer-options';
import { MeetingPlaceContext } from '../../../contexts/meeting-place-provider';
import { OpeningHoursException } from '../right/opening-hours-exceptions';
import { CATEGORY_STORE_INFO, useTracking } from '../../../hooks/use-tracking';

type PlacePreviewProps = {
    activeFloorPlanId: string;
    layoutStyling?: string;
};

type OpeningHours = ShopType['openingHours'];

export const PlacePreview = ({ layoutStyling, activeFloorPlanId }: PlacePreviewProps) => {
    const [isToggleOpen, setIsToggleOpen] = useState(false);
    const { getTranslation } = useLocalization();
    const { settings, locale, libraryUrlSubdirectoryCollection, domain } = useContext(MeetingPlaceContext);
    const place = useFindPlace(activeFloorPlanId);
    const { trackLeftPanelInteraction } = useTracking();

    useEffect(() => {
        setIsToggleOpen(false);
    }, [activeFloorPlanId]);

    if (!place) {
        return null;
    }

    const mainCategory = place?.primaryCategory ?? place.brand?.primaryCategory;
    const secondaryCategories = place?.secondaryCategoriesCollection?.items ?? place.brand?.secondaryCategoriesCollection?.items;
    const allCategories = [mainCategory, ...secondaryCategories]
        .map(term => term?.name)
        .filter(t => t)
        .join(', ');

    const contentfulRendererOptions = place.description
        ? getRichTextRendererOptions(
              place.description.links,
              settings.settings,
              locale || 'en',
              libraryUrlSubdirectoryCollection.items,
              domain
          )
        : undefined;

    return (
        <>
            <div className={clsx('relative flex flex-col gap-2 overflow-auto rounded bg-beige', layoutStyling)}>
                <img
                    src={place?.logo?.url || place?.brand?.logo?.url || ''}
                    alt={place?.logo?.url || place?.brand?.logo?.url || ''}
                    className="absolute left-4 top-4 z-10 w-16 rounded-md bg-white"
                />
                <img src={place?.coverImage?.url || place?.brand?.coverImage?.url || 'static/img/dynamic-stroke-1.svg'} />
                <div className="flex flex-col p-4">
                    <h2 className="pb-2 text-xl font-semibold">{place?.name || place.brand?.name}</h2>
                    {allCategories.length ? <p className="mb-6 text-sm">{allCategories}</p> : null}
                    <OpenToday place={place} />

                    <div className="flex items-center justify-between font-bold">
                        <button
                            onClick={() => {
                                setIsToggleOpen(!isToggleOpen);
                                if (!isToggleOpen) {
                                    trackLeftPanelInteraction(CATEGORY_STORE_INFO, place?.name || place.brand?.name || '');
                                }
                            }}
                            data-tracking="expand-shop-info-text-btn"
                        >
                            {isToggleOpen ? getTranslation('Show less') : getTranslation('Show more')}
                        </button>
                        <button
                            className={clsx('h-12 w-12 cursor-pointer transition-transform duration-300', {
                                'rotate-180': isToggleOpen,
                            })}
                            onClick={() => {
                                setIsToggleOpen(!isToggleOpen);
                                if (!isToggleOpen) {
                                    trackLeftPanelInteraction(CATEGORY_STORE_INFO, place?.name || place.brand?.name || '');
                                }
                            }}
                            data-tracking="expand-shop-info-btn"
                        >
                            <Toggle />
                        </button>
                    </div>
                    <div
                        className={clsx('grid h-auto grid-cols-1 overflow-y-scroll transition-all duration-150', {
                            'grid-rows-[0fr]': !isToggleOpen,
                            'grid-rows-[1fr] pb-4 pt-8': isToggleOpen,
                        })}
                    >
                        <div className="overflow-hidden">
                            <CmsText json={place?.description?.json} rendererOptions={contentfulRendererOptions} />
                            {/* It can happen that the description text has an line break at the end. It's better we have double line breaks than no line breaks */}
                            <OpeningHours parentStyling="flex flex-col gap-2 pt-8" activeShop={place} />
                            <OpeningHoursException parentStyling="flex flex-col gap-2 pt-8" activeShop={place} />
                            {/* There are some discussions currently regarding whether or not we need this */}
                            {/* I'm leaving it be for now, but if we don't use it we can also remove the lib */}
                            {/* we use to generate the QR codes as well */}
                            {/* <ShopLinks place={place} /> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
