import { useContext } from 'react';
import { isPast } from 'date-fns';
import { MeetingPlaceContext } from '../contexts/meeting-place-provider';
import { OfferType } from '../services/meeting-place/offers';

export enum TypeOfOffer {
    specialOffer = 'special-offer',
    ikeaFamilyMemberOffer = 'ikea-family-member-offer',
    regularOffer = 'regular-offer',
}

export const useOffers = () => {
    const { shops, offers } = useContext(MeetingPlaceContext);

    const getActiveOffers = () => offers.items.filter(offer => !isPast(offer.validTo));
    const getOfferById = (id?: string) => (id ? offers.items.find(({ sys }) => sys && sys.id === id) : null);
    const getTenantForOffer = (offer?: OfferType | null) =>
        offer ? shops.items.find(({ sys }) => offer.tenant && sys.id === offer.tenant.sys.id) : null;
    const getBrandImageForOffer = (offer?: OfferType | null) => {
        const tenantOffer = getTenantForOffer(offer);
        return tenantOffer?.logo?.url || tenantOffer?.brand?.logo?.url || null;
    };

    const getRelatedOffers = (offer?: OfferType | null) => {
        if (!offer) {
            return [];
        }

        return offers.items.filter(currentOffer => {
            // Exclude the current offer
            if (currentOffer.sys?.id === offer.sys?.id) {
                return false;
            }

            if (currentOffer.tenant?.sys?.id !== offer.tenant?.sys?.id) {
                return false;
            }

            return !isPast(currentOffer.validTo);
        });
    };

    /**
     * This is because in contenful the typeOfOffer is snake cased and it seems our graphql json serializer is camel cased. We just want to make sure we have a consistent way of handling this.
     * @param typeOfOffer
     * @returns
     */
    const formatTypeOfOffer = (typeOfOffer: string) => {
        switch (typeOfOffer.toLocaleLowerCase()) {
            case 'special-offer':
            case 'specialoffer':
                return TypeOfOffer.specialOffer;
            case 'ikea-family-member-offer':
            case 'ikeafamilymemberoffer':
                return TypeOfOffer.ikeaFamilyMemberOffer;
            default:
                return TypeOfOffer.regularOffer;
        }
    };

    return {
        getActiveOffers,
        getOfferById,
        getTenantForOffer,
        getBrandImageForOffer,
        getRelatedOffers,
        formatTypeOfOffer,
    };
};
