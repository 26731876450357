import clsx from 'clsx';
import { useContext } from 'react';
import { MeetingPlaceContext } from '../../contexts/meeting-place-provider';

export const TagsSmall = ({ tags = [] }: { tags?: { name: string }[] }) => {
    const { is2k, is4k, isPortrait } = useContext(MeetingPlaceContext);

    if (!Array.isArray(tags) || !tags?.length) {
        return null;
    }
    return (
        <div className="flex h-full flex-col justify-between pb-4">
            <div data-component="tags" className="flex max-h-full max-w-full flex-wrap items-start justify-start gap-2  pt-2">
                {tags.map(({ name }, index) => (
                    <div
                        key={index}
                        className={clsx(
                            'rounded-[4px] border-2  ',
                            {
                                'border-[#8EBDA8] bg-[#C0D9CA]': index === 0,
                                'border-[#adaaa3]': index !== 0,
                            },
                            {
                                'px-[0.7rem] py-[0.6rem] text-[2rem]': isPortrait && is4k,
                                'px-[0.5rem] py-[0.3rem] text-[1rem]': isPortrait && is2k && !is4k,
                                'px-2 text-xs': !isPortrait,
                            }
                        )}
                    >
                        {name}
                    </div>
                ))}
            </div>
        </div>
    );
};
