import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { format } from 'date-fns';
import clsx from 'clsx';
import { Close } from '../../assets/svg/close';
import { Settings } from '../../assets/svg/settings';
import { A11y } from '../../assets/svg/a11y';
import { Language } from '../../assets/svg/language';
import { useLocalization } from '../../localization/use-localization';
import { LanguagePicker } from './right/language-picker';
import { useEvents } from '../../hooks/use-events';
import { useOffers } from '../../hooks/use-offers';
import { MeetingPlaceContext } from '../../contexts/meeting-place-provider';
import {
    CATEGORY_RIGHT_FOOTER_MENU,
    ELEMENT_RIGHT_EVENTS,
    ELEMENT_RIGHT_FEEDBACK,
    ELEMENT_RIGHT_INFORMATION,
    ELEMENT_RIGHT_OFFERS,
    useTracking,
} from '../../hooks/use-tracking';

export type MenuViewType = '' | 'settings' | 'accessibility' | 'language';

export type Props = {
    onUpdateMenuItem?: Dispatch<SetStateAction<boolean>>;
    floating?: boolean;
};

export const FooterSettings: React.FC<Props> = ({ onUpdateMenuItem, floating = false }) => {
    const { footer, settings, appStarted, appVersion } = useContext(MeetingPlaceContext);
    const { getTranslation } = useLocalization();
    const { getActiveEvents } = useEvents();
    const { getActiveOffers } = useOffers();
    const [menuItem, setMenuItem] = useState<MenuViewType>('');
    const menuItems: MenuViewType[] = ['settings', 'language'];
    const { trackRightPanelInteraction } = useTracking();

    const hasInfoPage = () =>
        settings?.settings?.wayfinderSettings?.informationMenuCollection?.items?.length
            ? settings.settings.wayfinderSettings.informationMenuCollection.items.filter(d => d)?.length > 0
            : false;

    const hasFeedbackPage = () => !!footer.items.find(item => item.footer?.feedbackUrlWayfinder);

    useEffect(() => {
        onUpdateMenuItem?.(!!menuItem);
    }, [menuItem, onUpdateMenuItem]);

    const getActiveMenu = () => {
        switch (menuItem) {
            case 'accessibility':
                return null;

            case 'settings':
                return (
                    <div className="mb-4 flex flex-col gap-6">
                        {getActiveOffers().length ? (
                            <NavLink
                                to="/offers"
                                className={({ isActive }) => (isActive ? 'font-bold' : '')}
                                data-tracking="open-offers-btn"
                                onClick={() => {
                                    trackRightPanelInteraction(CATEGORY_RIGHT_FOOTER_MENU, ELEMENT_RIGHT_OFFERS);
                                }}
                            >
                                {getTranslation('Offers')}
                            </NavLink>
                        ) : null}
                        {getActiveEvents().length ? (
                            <NavLink
                                to="/events"
                                className={({ isActive }) => (isActive ? 'font-bold' : '')}
                                data-tracking="open-events-btn"
                                onClick={() => {
                                    trackRightPanelInteraction(CATEGORY_RIGHT_FOOTER_MENU, ELEMENT_RIGHT_EVENTS);
                                }}
                            >
                                {getTranslation('Events')}
                            </NavLink>
                        ) : null}
                        {hasInfoPage() ? (
                            <NavLink
                                to="/information"
                                className={({ isActive }) => (isActive ? 'font-bold' : '')}
                                data-tracking="open-information-btn"
                                onClick={() => {
                                    trackRightPanelInteraction(CATEGORY_RIGHT_FOOTER_MENU, ELEMENT_RIGHT_INFORMATION);
                                }}
                            >
                                {getTranslation('Info')}
                            </NavLink>
                        ) : null}
                        {hasFeedbackPage() ? (
                            <NavLink
                                to="/feedback"
                                className={({ isActive }) => (isActive ? 'font-bold' : '')}
                                data-tracking="feedback-btn"
                                onClick={() => {
                                    trackRightPanelInteraction(CATEGORY_RIGHT_FOOTER_MENU, ELEMENT_RIGHT_FEEDBACK);
                                }}
                            >
                                {getTranslation('Feedback')}
                            </NavLink>
                        ) : null}
                    </div>
                );

            case 'language':
                return <LanguagePicker />;
        }

        return null;
    };

    const closeBtn = (
        <button role="button" onClick={() => setMenuItem('')} className="w-16 overflow-hidden xl:w-24" data-tracking="close-settings-btn">
            <Close />
        </button>
    );

    const renderIcon = (item: MenuViewType) => {
        switch (item) {
            case 'settings':
                return <Settings />;
            case 'accessibility':
                return <A11y />;
            case 'language':
                return <Language />;
        }

        return null;
    };

    return (
        <div
            data-component="footer-settings"
            className={clsx('rounded-t-2xl p-8 xl:p-12 xl:pb-6', {
                'bg-white/95': !floating,
                'bg-white/95 shadow-sidebar': menuItem,
            })}
        >
            {getActiveMenu()}
            <div
                className={clsx('grid w-full grid-cols-2 place-items-center', {
                    'pt-8': menuItem,
                })}
            >
                {menuItems.map((item, key) => (
                    <div key={key}>
                        {menuItem === item ? (
                            closeBtn
                        ) : (
                            <button
                                role="button"
                                onClick={() => setMenuItem(item)}
                                data-tracking={`open-${item}-btn`}
                                className="w-16 overflow-hidden xl:w-24"
                            >
                                {renderIcon(item)}
                            </button>
                        )}
                    </div>
                ))}
            </div>
            <p className="pt-8 text-center text-sm">
                <strong>v.{appVersion}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;{format(appStarted, 'yyyy/LL/dd kk:mm:ss')}
            </p>
        </div>
    );
};
