import { useCallback, useContext, useMemo } from 'react';
import { MeetingPlaceContext } from '../contexts/meeting-place-provider';
import { ShopType } from '../services/meeting-place/shops';
import { ServiceType } from '../services/meeting-place/services';
import { useLocation } from 'react-router-dom';
import { useLocalization } from '../localization/use-localization';

/**
 * All events will need these 3 properties.
 */
export type EventObject = {
    domain: string;
    page: string;
    market: string;
    customLanguage: string;
    fromKioskId: string;
    mpTagId: string;
    customSessionId: string;
    /**
     * If this parameter exists, the event will automatically be treated as debug
     */
    debug_mode?: boolean;
    event?: string;
    category?: string;
    element?: string;
    clickText?: string;
};

/* Categories */
export const CATEGORY_RIGHT_SEARCH_ICON = 'search icon';
export const CATEGORY_RIGHT_SEARCH_BY = 'search by';
export const CATEGORY_RIGHT_FOOTER_MENU = 'footer menu';
export const CATEGORY_RIGHT_POPULAR_SEARCHES = 'Popular searches';
export const CATEGORY_EVENTS = 'events';
export const CATEGORY_OFFERS = 'offers';
export const CATEGORY_FRONT_MAP = 'map';
export const CATEGORY_FLOOR_PICKER = 'floor picker';
export const CATEGORY_ZOOM_TOOL = 'zoom tool';
export const CATEGORY_LEFT_OFFER = 'offer';
export const CATEGORY_STORE_INFO = 'store info';
export const CATEGORY_MAP_NAVIGATOR = 'map navigator';

/* Elements */

export const ELEMENT_MAP_NAVIGATOR = 'map navigator';
export const ELEMENT_RIGHT_SEARCH_ICON = 'Search icon';
export const ELEMENT_RIGHT_A_TO_Z = 'A - Z';
export const ELEMENT_RIGHT_CATEGORIES = 'Categories';
export const ELEMENT_RIGHT_FOOD_AND_BEVERAGE = 'Food & beverage';
export const ELEMENT_RIGHT_SERVICES = 'Services';
export const ELEMENT_RIGHT_OFFERS = 'offers';
export const ELEMENT_RIGHT_EVENTS = 'events';
export const ELEMENT_RIGHT_INFORMATION = 'information';
export const ELEMENT_RIGHT_FEEDBACK = 'feedback';
export const ELEMENT_FLOOR_0 = '0';
export const ELEMENT_FLOOR_1 = '1';
export const ELEMENT_FLOOR_2 = '2';
export const ELEMENT_PLUS = '+';
export const ELEMENT_MINUS = '-';

/* LOCATIONS */
export const LOCATION_LEFT_PANEL_MAP = 'left panel map';
export const LOCATION_FRONT_PAGE = 'front page';
export const LOCATION_RIGHT_PANEL = 'right panel';
export const LOCATION_MAP_NAVIGATION = 'map navigation';

/**
 * All tracking automatically appends custom properties and page location. Automatically adds debug mode if in dev mode.
 */
export const useTracking = () => {
    const EVENT_NAME_PREFIX = 'wf01';
    const EVENT_NAME_PREFIX_2 = 'wf02';
    const { kioskId, mpTagId, shops, foodDrink, services } = useContext(MeetingPlaceContext);
    const { pathname } = useLocation();
    const { lang, locale } = useContext(MeetingPlaceContext);
    const { getCustomCountryCode } = useLocalization();

    const eventGeneralObject: EventObject = useMemo(() => {
        const debugMode = {
            debug_mode: true,
        };
        const eventObject = {
            domain: window.location.host,
            page: pathname,
            market: getCustomCountryCode(locale || '') || '',
            customLanguage: lang || '',
            fromKioskId: kioskId || '',
            mpTagId: mpTagId || '',
            customSessionId: `${kioskId || ''}-${localStorage.getItem('customSessionId') || ''}`,
        };

        return window.location.host.includes('localhost') ? { ...eventObject, ...debugMode } : eventObject;
    }, [getCustomCountryCode, kioskId, lang, locale, mpTagId, pathname]);

    const trackNavigation = useCallback(
        (floorPlanId: string, currentNavInstruction: number, navigationPlayback?: 'start' | 'change' | 'end') => {
            const EVENT_NAME = `${EVENT_NAME_PREFIX}_floorplan_wayfinding`;

            let destinationName;
            const shopsAndFood = [...shops.items, ...foodDrink.items];

            const foundShopOrFood = shopsAndFood.find(destination => {
                if (!destination.floorPlanIDs) {
                    return false;
                }

                return destination.floorPlanIDs!.includes(floorPlanId);
            }) as ShopType;

            if (foundShopOrFood) {
                destinationName = foundShopOrFood?.name || foundShopOrFood?.brand?.name || 'Name is missing';
            } else {
                const service = services.items.find(destination => {
                    if (!destination.floorPlanIDs) {
                        return false;
                    }
                    return destination.floorPlanIDs!.includes(floorPlanId);
                }) as ServiceType;

                destinationName = service?.name || service?.serviceType?.name || 'Name is missing';
            }

            const eventSpecificObject = {
                event: EVENT_NAME,
                toStoreName: destinationName,
                currentNavInstruction: currentNavInstruction,
                navigationPlayback: navigationPlayback || '',
            };
            const mergedEventObject = { ...eventGeneralObject, ...eventSpecificObject };
            window.dataLayer.push(mergedEventObject);
        },
        [eventGeneralObject, foodDrink.items, shops.items, services.items]
    );

    const trackSearch = useCallback(
        (searchTerm: string) => {
            const EVENT_NAME = `${EVENT_NAME_PREFIX}_user_searched`;
            const eventSpecificObject = {
                event: EVENT_NAME,
                searchTerm: searchTerm,
            };
            const mergedEventObject = { ...eventGeneralObject, ...eventSpecificObject };
            window.dataLayer.push(mergedEventObject);
        },
        [eventGeneralObject]
    );

    /**
     * GA automatically tracks page views and since we're using routes, it should know where we are.
     * But we also need some extra data that the automatic tracking doesn't use.
     * Unless we configure the tag to collect that specific data but then we'd need to put it somewhere accessible.s
     * @param location - Supply a location to override the default location from useLocation()
     */
    const trackPage = useCallback(
        (location?: string) => {
            const EVENT_NAME = `${EVENT_NAME_PREFIX}_viewed_page`;

            const eventSpecificObject = {
                event: EVENT_NAME,
                page: location || pathname,
            };
            const mergedEventObject = { ...eventGeneralObject, ...eventSpecificObject };
            window.dataLayer.push(mergedEventObject);
        },
        [eventGeneralObject, pathname]
    );

    const trackSessionStart = useCallback(() => {
        const EVENT_NAME = `${EVENT_NAME_PREFIX}_session_start`;
        const eventSpecificObject = {
            event: EVENT_NAME,
        };
        const mergedEventObject = { ...eventGeneralObject, ...eventSpecificObject };
        window.dataLayer.push(mergedEventObject);
    }, [eventGeneralObject]);

    const trackSessionEnd = useCallback(() => {
        const EVENT_NAME = `${EVENT_NAME_PREFIX}_session_end`;
        const eventSpecificObject = {
            event: EVENT_NAME,
        };
        const mergedEventObject = { ...eventGeneralObject, ...eventSpecificObject };
        window.dataLayer.push(mergedEventObject);
        generateNewSessionId();
    }, [eventGeneralObject]);

    const trackFrontClick = useCallback(
        (category: string, element: string, clickText: string) => {
            const EVENT_NAME = `${EVENT_NAME_PREFIX_2}_front_click`;
            const eventSpecificObject = {
                event: EVENT_NAME,
                category,
                element,
                clickText,
            };
            const mergedEventObject = { ...eventGeneralObject, ...eventSpecificObject };
            window.dataLayer.push(mergedEventObject);
        },
        [eventGeneralObject]
    );

    const trackRightPanelInteraction = useCallback(
        (category: string, element: string) => {
            const EVENT_NAME = `${EVENT_NAME_PREFIX_2}_right_panel_click`;
            const eventSpecificObject = {
                event: EVENT_NAME,
                category,
                element,
            };
            const mergedEventObject = { ...eventGeneralObject, ...eventSpecificObject };
            window.dataLayer.push(mergedEventObject);
        },
        [eventGeneralObject, pathname]
    );

    const trackMapPanelInteraction = useCallback(
        (category: string, element: string) => {
            const EVENT_NAME = `${EVENT_NAME_PREFIX_2}_map_panel_click`;
            const eventSpecificObject = {
                event: EVENT_NAME,
                category,
                element,
            };
            const mergedEventObject = { ...eventGeneralObject, ...eventSpecificObject };
            window.dataLayer.push(mergedEventObject);
        },
        [eventGeneralObject]
    );

    const trackLeftPanelInteraction = useCallback(
        (category: string, toStoreName: string) => {
            const EVENT_NAME = `${EVENT_NAME_PREFIX_2}_left_panel_click`;
            const eventSpecificObject = {
                event: EVENT_NAME,
                category,
                toStoreName,
            };
            const mergedEventObject = { ...eventGeneralObject, ...eventSpecificObject };
            window.dataLayer.push(mergedEventObject);
        },
        [eventGeneralObject]
    );

    const trackOfferClick = useCallback(
        (location: string, toStoreName: string, clickText: string) => {
            const EVENT_NAME = `${EVENT_NAME_PREFIX_2}_offer_click`;
            const eventSpecificObject = {
                event: EVENT_NAME,
                toStoreName,
                location,
                clickText,
            };
            const mergedEventObject = { ...eventGeneralObject, ...eventSpecificObject };
            window.dataLayer.push(mergedEventObject);
        },
        [eventGeneralObject]
    );

    const trackSelectSearch = useCallback(
        (category: string, element: string, toStoreName: string, location: string) => {
            const EVENT_NAME = `${EVENT_NAME_PREFIX_2}_select_search`;
            const eventSpecificObject = {
                event: EVENT_NAME,
                category,
                location,
                element,
                toStoreName,
            };
            const mergedEventObject = { ...eventGeneralObject, ...eventSpecificObject };
            window.dataLayer.push(mergedEventObject);
        },
        [eventGeneralObject]
    );

    const trackSeeAll = useCallback(
        (category: string, location: string) => {
            const EVENT_NAME = `${EVENT_NAME_PREFIX_2}_see_all_click`;
            const eventSpecificObject = {
                event: EVENT_NAME,
                category,
                location,
            };
            const mergedEventObject = { ...eventGeneralObject, ...eventSpecificObject };
            window.dataLayer.push(mergedEventObject);
        },
        [eventGeneralObject]
    );

    return {
        trackNavigation,
        trackSearch: trackSearch,
        trackPage,
        trackSessionStart,
        trackSessionEnd,
        trackFrontClick,
        trackRightPanelInteraction,
        trackMapPanelInteraction,
        trackLeftPanelInteraction,
        trackOfferClick,
        trackSelectSearch,
        trackSeeAll,
    };
};

export const generateNewSessionId = () => {
    const date = new Date();
    const ms = date.getMilliseconds();
    const s = date.getSeconds();
    const m = date.getMinutes();
    const h = date.getHours();
    const d = date.getDate();
    const mo = date.getMonth();
    const y = date.getFullYear();
    const timestamp = `${y}${mo}${d}${h}${m}${s}${ms}`;

    localStorage.setItem('customSessionId', timestamp);
};
