import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { IoArrowBack } from 'react-icons/io5';
import { Essential, Place } from '../../../types/visiomap';
import { Search } from './search';
import { AtoZed } from './a-to-z';
import { Categories } from './categories';
import { FoodDrink } from './food-drink';
import { Services } from './services';
import { usePopularSearch } from '../../../hooks/use-popular-search';
import { RightLanding } from './right-landing';
import { FooterSettings } from '../footer-settings';
import { Directions } from './directions';
import { NavigationInstructionsType } from '../../visioweb/visiomap';
import { Preference } from '../../visioweb/custom-route';
import ButtonClickArea from '../../button-click-area/button-click-area';
import {
    CATEGORY_RIGHT_SEARCH_BY,
    CATEGORY_RIGHT_SEARCH_ICON,
    ELEMENT_RIGHT_A_TO_Z,
    ELEMENT_RIGHT_CATEGORIES,
    ELEMENT_RIGHT_FOOD_AND_BEVERAGE,
    ELEMENT_RIGHT_SEARCH_ICON,
    ELEMENT_RIGHT_SERVICES,
    useTracking,
} from '../../../hooks/use-tracking';

export type ViewType = '' | 'search' | 'A-Z' | 'categories' | 'restaurants' | 'services';

type Props = {
    visioWebEssential: Essential | null;
    selectPlace: (floorPlanId: string | Place | null) => void;
    activeFloorPlanId: string | null;
    navigationInstructions: NavigationInstructionsType[];
    activeInstructionIndex: number;
    setActiveInstructionIndex: React.Dispatch<React.SetStateAction<number>>;
    goToNavigationInstruction: (instrution: NavigationInstructionsType) => void;
    routePreference: Preference;
    setRoutePreference: (choice: Preference) => void;
};

export const RightSidePanel: React.FC<Props> = ({
    visioWebEssential,
    selectPlace,
    activeFloorPlanId,
    navigationInstructions,
    activeInstructionIndex,
    setActiveInstructionIndex,
    goToNavigationInstruction,
    routePreference,
    setRoutePreference,
}) => {
    const nodeRef = useRef(null);
    const [view, setView] = useState<ViewType>('');
    const [menuOpen, setMenuOpen] = useState(false);
    const [useAccessibleRoute, setUseAccessibleRoute] = useState(false);
    const { addPopularSearch } = usePopularSearch();
    const { trackRightPanelInteraction } = useTracking();

    const superOnClick = (floorPlanId: string) => {
        addPopularSearch(floorPlanId);
        selectPlace(floorPlanId || null);
    };

    useEffect(() => {
        switch (view) {
            case 'search':
                trackRightPanelInteraction(CATEGORY_RIGHT_SEARCH_ICON, ELEMENT_RIGHT_SEARCH_ICON);
                break;
            case 'A-Z':
                trackRightPanelInteraction(CATEGORY_RIGHT_SEARCH_BY, ELEMENT_RIGHT_A_TO_Z);
                break;
            case 'categories':
                trackRightPanelInteraction(CATEGORY_RIGHT_SEARCH_BY, ELEMENT_RIGHT_CATEGORIES);
                break;
            case 'restaurants':
                trackRightPanelInteraction(CATEGORY_RIGHT_SEARCH_BY, ELEMENT_RIGHT_FOOD_AND_BEVERAGE);
                break;
            case 'services':
                trackRightPanelInteraction(CATEGORY_RIGHT_SEARCH_BY, ELEMENT_RIGHT_SERVICES);
                break;
        }
    }, [trackRightPanelInteraction, view]);

    const renderSection = () => {
        const props = {
            menuOpen,
            visioWebEssential,
            onClick: (floorplanId?: string) => superOnClick(floorplanId || ''),
        };

        switch (view) {
            case 'search':
                return <Search {...props} activeFloorPlanId={activeFloorPlanId} />;
            case 'A-Z':
                return <AtoZed {...props} />;
            case 'categories':
                return <Categories {...props} />;
            case 'restaurants':
                return <FoodDrink {...props} />;
            case 'services':
                return <Services {...props} />;
        }

        return <RightLanding setView={setView} selectPlace={selectPlace} />;
    };
    return (
        <div className="relative flex h-full flex-col justify-between bg-primary">
            {navigationInstructions.length ? null : (
                <div ref={nodeRef}>
                    {view && navigationInstructions.length < 1 ? (
                        <>
                            <div className="h-16 bg-accent" />
                            <div className="flex h-16 w-full cursor-pointer items-center bg-white/95 p-4">
                                <ButtonClickArea
                                    onClick={() => {
                                        setView('');
                                        selectPlace(null);
                                    }}
                                >
                                    <IoArrowBack className="text-4xl" onClick={() => {}} />
                                </ButtonClickArea>
                            </div>
                        </>
                    ) : null}
                </div>
            )}
            {navigationInstructions.length ? null : (
                <div
                    className={clsx('relative h-full max-h-full overflow-auto', {
                        'pt-12 xl:pt-24': !(view || navigationInstructions.length),
                        'flex flex-col': view === 'search', // layout&padding&margin managed by component
                        'px-0': view === 'search',
                        'scrollbar-show mx-2 px-2': view !== 'search' && view !== 'A-Z',
                    })}
                >
                    {navigationInstructions.length ? null : renderSection()}
                </div>
            )}

            {navigationInstructions.length ? (
                <div
                    className={clsx('inset-x-0 min-h-0 flex-[1_1_auto] flex-col bg-white px-0 transition duration-300', {
                        'translate-x-full overflow-hidden opacity-50': !activeFloorPlanId,
                        'scrollbar-hide translate-x-0 overflow-scroll px-4 opacity-100': activeFloorPlanId,
                    })}
                >
                    <Directions
                        onClose={() => selectPlace(null)}
                        directions={navigationInstructions}
                        activeInstructionIndex={activeInstructionIndex}
                        setActiveInstructionIndex={setActiveInstructionIndex}
                        visioWebEssential={visioWebEssential}
                        useAccessibleRoute={useAccessibleRoute}
                        routePreference={routePreference}
                        setRoutePreference={setRoutePreference}
                        goToNavigationInstruction={goToNavigationInstruction}
                        toggleAccessibleRoute={() => setUseAccessibleRoute(!useAccessibleRoute)}
                        activeFloorPlanId={activeFloorPlanId}
                    />
                </div>
            ) : null}
            <div className=" flex-[0_1_auto]">{view !== 'search' ? <FooterSettings onUpdateMenuItem={setMenuOpen} /> : null}</div>
        </div>
    );
};
