import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createMemoryRouter } from 'react-router-dom';
import clsx from 'clsx';
import * as Sentry from '@sentry/react';
import { captureConsoleIntegration } from '@sentry/integrations';
import { routes as children } from './routes/routes';
import MeetingPlaceProvider from './contexts/meeting-place-provider';
import { getParamFromUrlOrDOM } from './helpers/get-param';
import { Cron } from './cron';
import { Reset } from './reset';
import { App } from './app';
import './index.css';

const router = createMemoryRouter([
    {
        path: '/',
        element: <App />,
        children,
    },
]);

export type RouterType = typeof router;

const reactRoot = document.getElementById('root')!;
const appVersion = reactRoot?.dataset?.appVersion ?? 'dev';
const verticalLayout =
    getParamFromUrlOrDOM('verticalMode') === 'enabled' ? 'base' : window.matchMedia('(orientation: portrait)').matches ? 'base' : '';
const is2k = window.matchMedia('(min-width: 1440px)').matches;
const is4k = window.matchMedia('(min-width: 2000px)').matches;

Sentry.init({
    dsn: 'https://2a734ac0523a969021cd170b50f37289@o478484.ingest.sentry.io/4506619817623552',
    integrations: [captureConsoleIntegration()],
    enabled: process.env.NODE_ENV !== 'development' && !window.location.hostname.includes('netlify'),
});

if (process.env.NODE_ENV !== 'development') {
    const isZoomedIn = () => {
        return window.visualViewport && window.visualViewport.scale > 1;
    };

    const handleMouseWheelEvent = (event: WheelEvent) => {
        const { ctrlKey } = event;
        if (ctrlKey) {
            // if pinch event, this will be true
            event.preventDefault();
            return;
        }
    };

    const handleTouchStartEvent = (event: TouchEvent) => {
        if (!event.isTrusted) return;
        if (event.touches.length > 1 && !isZoomedIn()) {
            event.preventDefault();
        }
    };

    document.addEventListener('wheel', handleMouseWheelEvent, {
        passive: false,
    });
    document.addEventListener('touchstart', handleTouchStartEvent, {
        passive: false,
    });
    document.addEventListener('touchmove', handleTouchStartEvent, {
        passive: false,
    });
    document.addEventListener('contextmenu', event => event.preventDefault());
    document.addEventListener('mousedown', event => event.preventDefault());
}

ReactDOM.createRoot(reactRoot).render(
    <React.StrictMode>
        <MeetingPlaceProvider appVersion={appVersion} appStarted={new Date()}>
            <div className="">
                <div
                    className={clsx('grid h-screen max-h-screen grid-cols-1', {
                        'grid-rows-vertical-base bg-dynamic-stroke bg-cover': verticalLayout && !is2k && !is4k,
                        'grid-rows-vertical-base-md bg-dynamic-stroke bg-cover': verticalLayout && is2k && !is4k,
                        'grid-rows-vertical-base-lg bg-dynamic-stroke bg-cover': verticalLayout && is2k && is4k,
                        'grid-rows-horizontal': !verticalLayout,
                    })}
                >
                    <div className="col-span-1 row-start-1 row-end-2" />
                    <div
                        className={clsx('col-span-1 row-start-2 row-end-3 bg-primary/95', {
                            'overflow-hidden shadow-sidebar': verticalLayout,
                        })}
                    >
                        <RouterProvider router={router} />
                    </div>
                    <div className="col-span-1 row-start-3 row-end-4" />
                </div>
            </div>
            <Cron />
            <Reset router={router} />
        </MeetingPlaceProvider>
    </React.StrictMode>
);

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register(import.meta.env.MODE === 'production' ? 'service-worker.js' : '/dev-sw.js?dev-sw', {
        type: import.meta.env.MODE === 'production' ? 'classic' : 'module',
    });
}
