import clsx, { ClassValue } from 'clsx';
import { Link } from 'react-router-dom';
import { useLocalization } from '../../localization/use-localization';
import { OccasionType } from '../../services/meeting-place/occasions';
import { TagsSmall } from './tags-small';
import { CATEGORY_EVENTS, useTracking } from '../../hooks/use-tracking';
import { useContext } from 'react';
import { MeetingPlaceContext } from '../../contexts/meeting-place-provider';

type Props = {
    event: OccasionType;
    classNames?: ClassValue;
    size?: 'small' | 'large' | 'xs';
};

export const EventSmall = ({ event }: Props) => {
    const { formatDate } = useLocalization();
    const { trackFrontClick } = useTracking();
    const { is2k, isFullhd, isPortrait, is4k } = useContext(MeetingPlaceContext);

    const eventDate = () => {
        const formattedStartDate = formatDate(event.startDate);
        const formattedEndDate = formatDate(event.endDate);

        if (formattedStartDate === formattedEndDate) {
            return `${formattedStartDate}`;
        }

        return `${formattedStartDate} - ${formattedEndDate}`;
    };

    return (
        <Link
            to={`/events/${event.sys.id}`}
            className="max-h-full rounded"
            data-tracking="view-event-btn"
            onClick={() => {
                trackFrontClick(CATEGORY_EVENTS, event.name, event.name);
            }}
        >
            <div className="flex-col items-start justify-between overflow-hidden">
                <div className="flex aspect-square h-[50%] flex-grow-0 items-center justify-center overflow-hidden rounded">
                    <div className="aspect-w-1 aspect-h-1 h-full w-full">
                        {event?.coverImage?.url ? (
                            <img src={event.coverImage.url} className={'h-full w-full rounded object-cover'} />
                        ) : null}
                    </div>
                </div>
                <div className="flex flex-col justify-between px-[5%] pt-2">
                    <div className="flex-[1]">
                        <p
                            className={clsx('truncate font-bold', {
                                'text-[2rem]': isPortrait && is4k,
                                'text-[1.5rem]': isPortrait && is2k && !is4k,
                                'text-[1rem]': isPortrait && isFullhd && !is2k,
                                'text-light': !isPortrait,
                            })}
                        >
                            {eventDate()}
                        </p>
                        <h2
                            className={clsx('overflow-hidden truncate  font-bold', {
                                'mt-[0.7rem] text-[2.2rem]': isPortrait && is4k,
                                'mt-[0.2rem] text-[1.7rem]': isPortrait && is2k && !is4k,
                                'mt-[0.2rem] text-[1.2rem]': isPortrait && isFullhd && !is2k,
                                'text-lg': !isPortrait,
                            })}
                        >
                            {event.name}
                        </h2>
                    </div>
                    <div
                        className={clsx({
                            'h-[2.2rem]': isPortrait && is4k,
                            'h-[1.5rem]': isPortrait && is2k && !is4k,
                            'h-[1rem]': isPortrait && isFullhd && !is2k,
                            'h-5': !isPortrait,
                        })}
                    />
                    <div className="flex-[1]">
                        <TagsSmall tags={event.eventTypesCollection?.items || []} />
                    </div>
                </div>
            </div>
        </Link>
    );
};
