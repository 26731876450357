import { QRCodeSVG } from 'qrcode.react';
import PhoneImage from '../../../assets/png/phone.png';
import { Close } from '../../../assets/svg/close';
import { useLocalization } from '../../../localization/use-localization';
import { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Preference } from '../../visioweb/custom-route';
import { MeetingPlaceContext } from '../../../contexts/meeting-place-provider';

type Props = {
    activeFloorPlanId: string | null;
    routePreference: Preference;
    setShowQr: (showQr: boolean) => void;
};

const NewQrCodeNav = (props: Props) => {
    const { getTranslation } = useLocalization();
    const kioskId = useContext(MeetingPlaceContext).kioskId;
    const domain = useContext(MeetingPlaceContext).domain;
    const locale = useContext(MeetingPlaceContext).locale;
    const mapSlug = useContext(MeetingPlaceContext).mapSlug;
    const { activeFloorPlanId, routePreference } = props;
    const [url, setUrl] = useState('');
    const [qrSize, setQrSize] = useState(80);
    const { is2k, isFullhd, isPortrait, is4k } = useContext(MeetingPlaceContext);

    useEffect(() => {
        setUrl('');
    }, [activeFloorPlanId]);

    useEffect(() => {
        let urlVal = '';
        if (domain.includes('http')) {
            if (domain.endsWith('/')) {
                urlVal = `${domain}${locale}/${mapSlug}?kioskId=${kioskId}&destination=${activeFloorPlanId}&routePreference=${routePreference}`;
            } else {
                urlVal = `${domain}/${locale}/${mapSlug}?kioskId=${kioskId}&destination=${activeFloorPlanId}&routePreference=${routePreference}`;
            }
        } else {
            if (domain.endsWith('/')) {
                urlVal = `https://${domain}${locale}/${mapSlug}?kioskId=${kioskId}&destination=${activeFloorPlanId}&routePreference=${routePreference}`;
            } else {
                urlVal = `https://${domain}/${locale}/${mapSlug}?kioskId=${kioskId}&destination=${activeFloorPlanId}&routePreference=${routePreference}`;
            }
        }
        setUrl(urlVal);
    });

    const handleResize = () => {
        if (isPortrait) {
            if (isFullhd && !is2k) {
                setQrSize(70);
            } else if (is2k && !is4k) {
                setQrSize(70);
            } else if (is4k) {
                setQrSize(100);
            } else {
                setQrSize(50);
            }
        } else {
            if (window.innerHeight > 1080 && window.innerHeight < 1440) {
                setQrSize(80);
            } else if (window.innerHeight >= 1440 && window.innerHeight <= 2000) {
                setQrSize(100);
            } else if (window.innerHeight > 2000) {
                setQrSize(150);
            } else {
                setQrSize(80);
            }
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (!url) {
        return null;
    }

    return (
        <div className="rounded-3xl  bg-accent">
            <div
                className={clsx(
                    'relative h-full w-full pl-28 pr-24',
                    { 'py-12': is2k || is4k || !isPortrait },
                    { 'py-6': (!is2k || !is4k) && isPortrait }
                )}
            >
                <div
                    className="absolute left-2 top-2  h-10 w-16 cursor-pointer"
                    onClick={() => {
                        props.setShowQr(false);
                    }}
                >
                    <Close />
                </div>
                <div className="flex h-full flex-row items-center justify-center space-x-6">
                    <div className="min-w-[20vw] max-w-[30vw]">
                        <div className="flex h-full flex-col justify-center">
                            <div
                                className={clsx(
                                    'font-bold',
                                    { 'text-3xl': is2k || is4k || !isPortrait },
                                    { 'text-xl': (!is2k || !is4k) && isPortrait }
                                )}
                            >
                                {getTranslation('Directions on your phone')}
                            </div>
                            <p
                                className={clsx(
                                    'mt-2',
                                    { 'text-xl': is2k || is4k || !isPortrait },
                                    { 'text-md': (!is2k || !is4k) && isPortrait }
                                )}
                            >
                                {getTranslation('ScanQrCodeNav')}
                            </p>
                        </div>
                    </div>
                    <div
                        className={clsx(
                            'relative h-full',
                            isPortrait && isFullhd && !is2k && !is4k ? 'min-w-[19vw] max-w-[19vw]' : 'min-w-[15vw] max-w-[15vw]'
                        )}
                    >
                        <div className="w-full rounded-2xl bg-white p-[8%]">
                            <QRCodeSVG value={url} level="L" size={qrSize} includeMargin={false} />
                        </div>
                        <div className="pointer-events-none absolute -right-4 bottom-0 flex rotate-6 transform justify-end">
                            <img src={PhoneImage} width={'50%'} className="pointer-events-auto" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewQrCodeNav;
