import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useContext } from 'react';
import { ArrowRight } from '../assets/svg/arrow-right';
import { FooterSettings } from './sidebar-components/footer-settings';
import { useLocalization } from '../localization/use-localization';
import { MeetingPlaceContext } from '../contexts/meeting-place-provider';

export const MapLinkSettings = (props: { showMapButton: boolean }) => {
    const { getTranslation } = useLocalization();
    const { showMapButton } = props;
    const { isPortrait, is2k, is4k } = useContext(MeetingPlaceContext);

    return (
        <div
            className={clsx('flex flex-col justify-between pt-24', {
                'h-screen': !isPortrait,
                'h-[1200px] ': isPortrait && !is2k && !is4k,
                'h-[1600px] ': isPortrait && is2k && !is4k,
                'h-[2400px] ': isPortrait && is2k && is4k,
            })}
        >
            {showMapButton ? (
                <Link to="/map" className="self-center text-6xl" data-tracking="back-to-map-btn">
                    <div className="flex items-center gap-2 font-sans">
                        {getTranslation('Map')}
                        <div className="w-16">
                            <ArrowRight />
                        </div>
                    </div>
                </Link>
            ) : (
                <div />
            )}
            <FooterSettings floating />
        </div>
    );
};
