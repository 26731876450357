import { useShops } from '../../../hooks/use-shops';
import { useTracking, CATEGORY_RIGHT_SEARCH_BY, LOCATION_RIGHT_PANEL, ELEMENT_RIGHT_FOOD_AND_BEVERAGE } from '../../../hooks/use-tracking';
import { Essential } from '../../../types/visiomap';
import { List } from '../list-components/list';

type Props = {
    visioWebEssential: Essential | null;
    onClick: (id?: string) => void;
};

export const FoodDrink = ({ visioWebEssential, onClick }: Props) => {
    const { getFoodDrinkList } = useShops(visioWebEssential);
    const { trackSelectSearch } = useTracking();

    return (
        <List
            items={getFoodDrinkList()}
            onClick={(floorplanId?: string, itemName?: string) => {
                onClick(floorplanId);
                trackSelectSearch(CATEGORY_RIGHT_SEARCH_BY, ELEMENT_RIGHT_FOOD_AND_BEVERAGE, itemName || '', LOCATION_RIGHT_PANEL);
            }}
        />
    );
};
