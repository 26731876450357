import { Essential, POI, Place } from '../../types/visiomap';
import { ShopType } from '../../services/meeting-place/shops';
import { FoodDrinkType } from '../../services/meeting-place/food-drink';
import { ServiceType } from '../../services/meeting-place/services';

export const setPlaceShopNames = (visioWebEssential: Essential, shops: ShopType[]) => {
    shops.forEach(shop => {
        if (!shop?.floorPlanIDs?.length) {
            return;
        }

        shop.floorPlanIDs.forEach(floorPlanID => {
            if (floorPlanID.startsWith(' ')) {
                const fpId = floorPlanID.substring(1);
                const place = visioWebEssential!._mapviewer!.getPlace(fpId);
                const poi = visioWebEssential!._mapviewer!.getPOI(fpId);
                if (place) {
                    configurePlace(place, shop?.name || shop?.brand?.name || '', poi);
                }
            } else {
                const place = visioWebEssential!._mapviewer!.getPlace(floorPlanID);
                const poi = visioWebEssential!._mapviewer!.getPOI(floorPlanID);
                if (place) {
                    configurePlace(place, shop?.name || shop?.brand?.name || '', poi);
                }
            }
        });
    });
};

export const setServiceNames = (visioWebEssential: Essential, services: ServiceType[]) => {
    const places = visioWebEssential.content.places;
    services.forEach(element => {
        if (element.floorPlanIDs && element.floorPlanIDs.length) {
            element.floorPlanIDs.forEach(el => {
                if (places[el]) {
                    places[el].name = element.name;
                }
            });
        }
    });
    visioWebEssential._mapviewer?.setupNavigationTranslator(places);
};

export const replaceServiceNames = (visioWebEssential: Essential) => {
    const places = visioWebEssential.content.places;
    Object.keys(places).forEach(el => {
        if (el === places[el].name) {
            places[el].name = '';
        }
    });
    visioWebEssential._mapviewer?.setupNavigationTranslator(places);
};

export const setPlaceFoodDrinksNames = (visioWebEssential: Essential, foodDrink: FoodDrinkType[]) => {
    foodDrink.forEach(foodDrink => {
        if (!foodDrink?.floorPlanIDs?.length) {
            return;
        }

        foodDrink.floorPlanIDs.forEach(floorPlanID => {
            const place = visioWebEssential!._mapviewer!.getPlace(floorPlanID);
            const poi = visioWebEssential!._mapviewer!.getPOI(floorPlanID);
            if (place) {
                configurePlace(place, foodDrink?.name || foodDrink?.brand?.name || '', poi);
            }
        });
    });
};

function configurePlace(place: Place, name: string, poi?: POI[]) {
    place.disabled = false;
    place.setName({
        text: name || '',
        clickable: false,
        textTextureHeight: 128,
    });

    if (!poi?.length) {
        return;
    }

    poi.forEach(p => {
        p.options({ fixed: true, flip: true, clickable: false });
    });
}

/** Used to hide all IDS on all tenants that potentially does not have names. Reserved shops for example. Also disables the place. */
export const hideAllPlaceNames = (visioWebEssential: Essential) => {
    const places = visioWebEssential!._mapviewer!.getPlaces();

    Object.keys(places).forEach(key => {
        const place = places[key] as Place;

        place.disabled = true;
        place.setName({
            text: '',
            clickable: false,
        });
    });
};
