import clsx, { ClassValue } from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { useLocalization } from '../../localization/use-localization';
import { OfferType } from '../../services/meeting-place/offers';
import { ChevronRight } from '../../assets/svg/chevron-right';
import { useOffers, TypeOfOffer } from '../../hooks/use-offers';
import { CATEGORY_OFFERS, LOCATION_LEFT_PANEL_MAP, useTracking } from '../../hooks/use-tracking';

type Props = {
    offer: OfferType;
    classNames?: ClassValue;
    size?: 'small' | 'large';
};

export const Offer = ({ offer, classNames = '', size = 'small' }: Props) => {
    const { getTranslation, formatDate } = useLocalization();
    const { getTenantForOffer, formatTypeOfOffer } = useOffers();
    const shop = getTenantForOffer(offer);
    const offerType = formatTypeOfOffer(offer.typeOfOffer.internalName);
    const location = useLocation();
    const { trackLeftPanelInteraction, trackOfferClick } = useTracking();
    return (
        <div className={clsx('flex h-full w-full flex-col justify-between overflow-hidden rounded', classNames)}>
            <div
                className={clsx(
                    'flex flex-1 flex-col overflow-hidden bg-beige',
                    {
                        'px-12 py-8': size === 'small',
                        'p-8': size === 'large',
                    },
                    {
                        'flex-1': typeof classNames === 'string' && classNames.includes('flex'), // This is a new line
                    }
                )}
            >
                {shop?.logo?.url || shop?.brand?.logo?.url ? (
                    <img
                        className={clsx('mb-12 w-16 rounded-md bg-white', {
                            'w-16': size === 'small',
                            'w-20': size === 'large',
                        })}
                        src={shop?.logo?.url || shop?.brand?.logo?.url}
                    />
                ) : (
                    <div className="mb-12 h-20" />
                )}
                {shop?.brand ? <h3 className="text-lg">{shop.brand.name}</h3> : null}
                <h2 className="text-2xl font-semibold">{offer.name}</h2>
                <div
                    className={clsx('font-bold', {
                        'text-ikea-family-offers': offerType === TypeOfOffer.ikeaFamilyMemberOffer,
                        'text-special-offers': offerType === TypeOfOffer.specialOffer,
                    })}
                >
                    {offerType !== TypeOfOffer.regularOffer && offer.typeOfOffer.name}
                </div>
                <p>
                    {getTranslation('Valid until')} {formatDate(offer.validTo)}
                </p>
                <div className="h-10" />
            </div>
            <div>
                <div className="relative flex h-8 items-center justify-between overflow-hidden">
                    <div className="absolute inset-x-6 h-8 bg-beige">
                        <div className="absolute inset-x-2 top-3 h-8 border-t-8 border-dotted border-white" />
                    </div>
                    <div className="h-16 w-16 flex-initial -translate-x-1/2 rounded-full border-[1rem] border-beige bg-transparent bg-cover bg-repeat" />
                    <div className="h-16 w-16 flex-initial translate-x-1/2 rounded-full border-[1rem] border-beige bg-transparent bg-cover bg-repeat" />
                </div>
                <Link
                    to={`/offers/${offer.sys.id}`}
                    className="flex items-center justify-between bg-beige p-8 font-bold"
                    data-tracking="view-offer-btn"
                    onClick={() => {
                        if (location.pathname.includes('map')) {
                            trackLeftPanelInteraction(CATEGORY_OFFERS, shop?.name || shop?.brand?.name || '');
                            trackOfferClick(LOCATION_LEFT_PANEL_MAP, shop?.name || shop?.brand?.name || '', getTranslation('View offer'));
                        }
                    }}
                >
                    <span>{getTranslation('View offer')}</span>
                    <span className="h-6 w-6">
                        <ChevronRight />
                    </span>
                </Link>
            </div>
        </div>
    );
};
