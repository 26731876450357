import { Document } from '@contentful/rich-text-types';
import { gql } from 'graphql-request';
import { ContentfulLinksType } from './occasions';
import { BaseFetchType } from './offers';

type fetchShopData = {
    meetingPlacePageTenantShopCollection: {
        total: number;
        items: ShopType[];
    };
};

export type BrandType = {
    description: {
        json: Document | null;
    } | null;
    name: string | null;
    primaryCategory: {
        name: string;
    } | null;
    secondaryCategoriesCollection: {
        items: {
            name: string;
        }[];
    };
    logo: {
        url: string;
    } | null;
    coverImage: {
        url: string;
    } | null;
};

export type SocialMediaLinks = {
    webUrl: string | null;
    internalName: string | null;
    instagramUrl: string | null;
    tikTokUrl: string | null;
    twitterUrl: string | null;
    facebookUrl: string | null;
    tripadvisorUrl: string | null;
    snapchatUrl: string | null;
    whatsAppUrl: string | null;
    telegramUrl: string | null;
    pinterestUrl: string | null;
    vkontakteUrl: string | null;
    youTubeUrl: string | null;
};

export type OpeningHoursExceptionsCollectionItem = {
    date: string;
    internalName: string;
    name: string;
    opens: string;
    closes: string;
};

export type ShopType = {
    brand: BrandType | null;
    logo: {
        url: string;
    } | null;
    coverImage: {
        url: string;
    } | null;
    /**
     * This is a list of all the corresponding ids on the map. You can match this with visiowebbessential.content.places
     */
    floorPlanIDs: string[] | null;
    floorPlanId: string; //not sure if this is in use
    name: string | null;
    sys: {
        id: string;
        environmentId: string;
        spaceId: string;
    };
    description: {
        json: Document | null;
        links: ContentfulLinksType;
    } | null;
    openingHours: {
        mondayOpen: string;
        mondayClose: string;
        tuesdayOpen: string;
        tuesdayClose: string;
        wednesdayOpen: string;
        wednesdayClose: string;
        thursdayOpen: string;
        thursdayClose: string;
        fridayOpen: string;
        fridayClose: string;
        saturdayOpen: string;
        saturdayClose: string;
        sundayOpen: string;
        sundayClose: string;
    } | null;
    openingHoursExceptionsCollection: {
        items: OpeningHoursExceptionsCollectionItem[];
    };
    socialMediaLinks: SocialMediaLinks | null;
    primaryCategory: {
        name: string;
    } | null;
    secondaryCategoriesCollection: {
        items: {
            name: string;
        }[];
    };
};

export const fetchMeetingPlaceTenantShop = async ({
    client,
    tagId,
    locale,
    skip = 0,
    limit = 8,
    preview = false,
}: BaseFetchType & {
    skip?: number;
    limit?: number;
}) => {
    const document = gql`
        query getShops($skip: Int, $limit: Int, $locale: String!, $tagId: [String], $preview: Boolean) {
            meetingPlacePageTenantShopCollection(
                skip: $skip
                limit: $limit
                locale: $locale
                preview: $preview
                where: { contentfulMetadata: { tags: { id_contains_some: $tagId } } }
            ) {
                total
                items {
                    logo {
                        url
                    }
                    coverImage {
                        url
                    }
                    floorPlanIDs
                    name
                    sys {
                        id
                    }
                    description {
                        json
                        links {
                            entries {
                                hyperlink {
                                    __typename
                                    sys {
                                        id
                                    }
                                    ... on MeetingPlacePageEditorial {
                                        slug
                                    }
                                    ... on MeetingPlacePageEvent {
                                        slug
                                    }
                                    ... on MeetingPlacePageOffer {
                                        slug
                                    }
                                }
                                block {
                                    __typename
                                    sys {
                                        id
                                    }
                                    ... on BlockLink {
                                        slug
                                        linkText
                                        externalLink
                                        internalLink {
                                            ... on MeetingPlacePageService {
                                                __typename
                                                slug
                                            }
                                            ... on MeetingPlacePageTenantEntertainment {
                                                __typename
                                                slug
                                            }
                                            ... on MeetingPlacePageOffer {
                                                __typename
                                                slug
                                            }
                                            ... on MeetingPlacePageTenantFoodBeverage {
                                                __typename
                                                slug
                                            }
                                            ... on MeetingPlacePageTenantShop {
                                                slug
                                                __typename
                                            }
                                            ... on MeetingPlacePageEvent {
                                                __typename
                                                slug
                                            }
                                            ... on MeetingPlacePageNewsItem {
                                                __typename
                                                slug
                                            }
                                        }
                                    }
                                }
                            }
                            assets {
                                block {
                                    __typename
                                    title
                                    url
                                    sys {
                                        id
                                    }
                                }
                                hyperlink {
                                    __typename
                                    url
                                    title
                                    sys {
                                        id
                                    }
                                }
                            }
                        }
                    }
                    openingHours {
                        mondayOpen
                        mondayClose
                        tuesdayOpen
                        tuesdayClose
                        wednesdayOpen
                        wednesdayClose
                        thursdayOpen
                        thursdayClose
                        fridayOpen
                        fridayClose
                        saturdayOpen
                        saturdayClose
                        sundayOpen
                        sundayClose
                    }
                    openingHoursExceptionsCollection {
                        items {
                            date
                            internalName
                            name
                            opens
                            closes
                        }
                    }
                    socialMediaLinks {
                        webUrl
                        internalName
                        instagramUrl
                        tikTokUrl
                        twitterUrl
                        facebookUrl
                        tripadvisorUrl
                        snapchatUrl
                        whatsAppUrl
                        telegramUrl
                        pinterestUrl
                        vkontakteUrl
                        youTubeUrl
                    }
                    brand {
                        name
                        primaryCategory {
                            name
                        }
                        secondaryCategoriesCollection {
                            items {
                                name
                            }
                        }
                        description {
                            json
                        }
                        logo {
                            url
                        }
                        coverImage {
                            url
                        }
                    }
                    primaryCategory {
                        name
                    }
                    secondaryCategoriesCollection {
                        items {
                            name
                        }
                    }
                }
            }
        }
    `;

    const response = await client.request<fetchShopData>({
        document,
        variables: {
            skip,
            limit,
            locale,
            tagId,
            preview,
        },
    });

    return response.meetingPlacePageTenantShopCollection;
};

export const fetchPaginatedMeetingPlaceTenantShops = async ({ client, tagId, locale, preview = false }: BaseFetchType) => {
    let skip = 0;
    let shops: ShopType[] = [];
    let total = -1;

    while (total === -1 || total > shops.length) {
        const collection = await fetchMeetingPlaceTenantShop({ client, tagId, locale, skip, preview });
        shops = [...shops, ...collection.items];
        skip += collection.items.length;
        total = collection.total && collection.total > 0 ? collection.total : 0;
    }

    return shops;
};
