import clsx from 'clsx';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa6';
import { getAnchorLink } from '../../../helpers/shops';
import { ListItem as ListItemType, ShopItem } from '../../../hooks/use-shops';
import { ListItemPlace } from './list-item-place';
import { MeetingPlaceContext } from '../../../contexts/meeting-place-provider';

type Props = {
    item: ListItemType;
    collapsable?: boolean;
    onClick: (id?: string, itemName?: string) => void;
    lastItem?: Element | null | undefined;
};

export const ListItem = ({ item, collapsable, onClick, lastItem }: Props) => {
    const { distances } = useContext(MeetingPlaceContext);
    const [isOpen, setIsOpen] = useState(!collapsable);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!isOpen && !collapsable) {
            return;
        }

        const lastIsOpen = containerRef.current === lastItem && isOpen;
        let timeout: NodeJS.Timeout;

        if (lastIsOpen && collapsable) {
            // ändra eventuellt till lastElementChild så att användaren ser alla butiker
            // och inte blir lurad att listan slutar där scrollen slutar?
            const scrollItem = lastItem?.firstElementChild;

            timeout = setTimeout(() => {
                scrollItem?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [isOpen, lastItem, collapsable]);

    const toggle = () => {
        if (!collapsable) {
            return;
        }

        setIsOpen(!isOpen);
    };

    const renderToggle = () => {
        if (!collapsable) {
            return null;
        }

        return (
            <span
                className={clsx('rounded-full bg-[#eae3da] p-2 transition-transform duration-300 ease-out', {
                    'rotate-180': isOpen,
                })}
            >
                <FaChevronDown size={10} color="#767571" />
            </span>
        );
    };

    const animationDuration = () => {
        const length = item.items.length;

        // the longer the items array the slower the animation, until max 1200ms
        if (length > 12) {
            return '600ms';
        }

        return length < 6 ? '300ms' : `${50 * length}ms`;
    };

    const getDistanceFromItem = (item: ShopItem) => {
        if (!distances?.length || !item.place?.id) {
            return 0;
        }

        const distance = distances.find(d => d.placeId === item.place?.id);
        return distance?.distance ? Math.round(distance.distance) : 0;
    };

    const sortedItems = useMemo(() => {
        if (!distances?.length) {
            return item.items;
        }

        return item.items.sort((a, b) => {
            if (!a.name || !b.name) {
                return 0;
            }

            const localeCompare = a.name.localeCompare(b.name);
            if (localeCompare === 1) {
                return 1;
            }

            const aDist = getDistanceFromItem(a);
            const bDist = getDistanceFromItem(b);

            return aDist < bDist ? -1 : 1;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.items, distances]);

    return (
        <div data-component="list-item" className="space-y-4 text-black" data-letter-id={getAnchorLink(item.title)} ref={containerRef}>
            <button
                className={clsx('grey-button', {
                    'cursor-default': !collapsable,
                })}
                onClick={toggle}
                data-tracking="expand-list-item-btn"
            >
                {item.title}
                {renderToggle()}
            </button>
            <div
                style={{
                    transitionDuration: animationDuration(),
                }}
                className={clsx('grid transition-[grid-template-rows] ease-in-out', {
                    'grid-rows-[1fr]': isOpen,
                    'grid-rows-[0fr]': !isOpen,
                })}
            >
                <div
                    className={clsx('overflow-hidden', {
                        'mb-4 space-y-4': isOpen,
                    })}
                >
                    {sortedItems.map((listItem, index) => (
                        <ListItemPlace
                            key={index}
                            item={listItem}
                            onClick={(floorplanId?: string) => {
                                onClick(floorplanId, listItem.name);
                            }}
                            distance={getDistanceFromItem(listItem)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
