import { NavLink } from 'react-router-dom';
import MapView from '../../assets/svg/map-view.tsx';
import { InfoPictogram } from '../../assets/svg/info-pictogram';
import { ArrowRight } from '../../assets/svg/arrow-right';
import { useLocalization } from '../../localization/use-localization';
import { CATEGORY_FRONT_MAP, ELEMENT_MAP_NAVIGATOR, useTracking } from '../../hooks/use-tracking';
import { useContext } from 'react';
import { MeetingPlaceContext } from '../../contexts/meeting-place-provider';
import clsx from 'clsx';

const MapCTASection = (props: { isPolygon: boolean | null | undefined }) => {
    const { getTranslation } = useLocalization();
    const { trackSessionStart } = useTracking();
    const { kioskId } = useContext(MeetingPlaceContext);
    const { isPolygon } = props;
    const { trackFrontClick } = useTracking();
    const { is2k, isFullhd, isPortrait, is4k } = useContext(MeetingPlaceContext);

    return (
        <div className="relative h-full">
            <NavLink
                to={kioskId ? '/map' : '/select-kiosk'}
                onClick={() => {
                    if (kioskId) {
                        trackSessionStart();
                        trackFrontClick(CATEGORY_FRONT_MAP, ELEMENT_MAP_NAVIGATOR, getTranslation('Map'));
                    }
                }}
                data-tracking="open-map-btn"
            >
                <div className="absolute bottom-0 right-0 h-full">
                    <MapView />
                </div>

                <div
                    className="absolute left-0 top-0 z-50 h-full w-full rounded bg-[#FFDC00]"
                    style={{
                        clipPath: isPolygon
                            ? 'polygon(0% 0%, 100% 0%, 100% 30%, 40% 100%, 0% 100%)'
                            : 'polygon(0% 0%, 100% 0%, 100% 30%, 0% 100%)',
                    }}
                >
                    <div
                        className={clsx(' flex max-h-[50%] w-full flex-row px-20 pt-20 ', {
                            'px-8 pt-9': isPortrait && isFullhd && !is2k,
                            'px-10 pt-11': is2k && !is4k && isPortrait,
                            'px-20 pt-20': is4k && isPortrait,
                        })}
                    >
                        <div className="flex-[2]">
                            <h1
                                className={clsx('leading-1 text-8xl font-bold', {
                                    'text-[2.5rem]': isPortrait && isFullhd && !is2k,
                                    'text-[3.3rem]': is2k && !is4k && isPortrait,
                                    'text-[5rem]': is4k && isPortrait,
                                })}
                            >
                                {getTranslation('LookingForSomethingSpecial')}
                            </h1>
                        </div>
                        {!isPortrait && (
                            <div className="flex w-full flex-[1] content-normal items-start justify-end">
                                <InfoPictogram />
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    <div
                        className={clsx(
                            'absolute bottom-12 right-12 flex items-center gap-2 rounded-[140px] bg-[#FFDC00] px-12 py-5 font-sans text-5xl shadow-map-button',
                            {
                                'bottom-8 right-8 px-[1.6rem] py-[.8rem] text-[2rem]': isPortrait && isFullhd && !is2k,
                                'bottom-10 right-10 px-[2rem] py-[1rem] text-[2.7rem]': is2k && !is4k && isPortrait,
                                'bottom-12 right-12 px-[3.5rem] py-[2rem] text-[4rem]': is4k && isPortrait,
                            }
                        )}
                    >
                        {getTranslation('Map')}
                        <div
                            className={clsx('w-16', {
                                'w-8': isPortrait && isFullhd && !is2k,
                                'w-9': is2k && !is4k && isPortrait,
                                'w-16': is4k && isPortrait,
                            })}
                        >
                            <ArrowRight />
                        </div>
                    </div>
                </div>
            </NavLink>
        </div>
    );
};

export default MapCTASection;
