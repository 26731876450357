import React, { useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useDebounce } from 'use-debounce';
import { Keyboard } from '../../keyboard/keyboard';
import { MeetingPlaceContext } from '../../../contexts/meeting-place-provider';
import { useLocalization } from '../../../localization/use-localization';
import { Essential } from '../../../types/visiomap';
import { ListItemPlace } from '../list-components/list-item-place';
import { useShops } from '../../../hooks/use-shops';
import { Search as SearchIcon } from '../../../assets/svg/search';
import { Close } from '../../../assets/svg/close';
import { CATEGORY_RIGHT_SEARCH_ICON, ELEMENT_RIGHT_SEARCH_ICON, LOCATION_RIGHT_PANEL, useTracking } from '../../../hooks/use-tracking';

type SearchProps = {
    visioWebEssential: Essential | null;
    onClick: (id?: string) => void;
    activeFloorPlanId: string | null;
};

export const Search: React.FC<SearchProps> = ({ visioWebEssential, onClick, activeFloorPlanId }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [mode, setMode] = useState<'letters' | 'digits'>('letters');
    const [debouncedSearchTerm] = useDebounce(searchTerm, 2000);
    const { shops, foodDrink } = useContext(MeetingPlaceContext);
    const { getTranslation } = useLocalization();
    const { getSearchResultListItems } = useShops(visioWebEssential);
    const { trackSelectSearch } = useTracking();

    const { trackSearch } = useTracking();
    const filteredItems = useMemo(() => {
        if (searchTerm.length < 1) {
            return [];
        }

        const regEx = new RegExp(`${searchTerm.replace(/^\s+|\s+$/g, '')}`, 'gi');
        // We maybe should add services here, but we'll leave this out for now
        const allSearchableItems = [...shops.items, ...foodDrink.items];

        const filteredItems = allSearchableItems
            .filter(item => {
                let hit = false;

                if (item.name && item.name.search(regEx) > -1) {
                    hit = true;
                }

                if (item?.brand?.name && item.brand?.name.search(regEx) > -1) {
                    hit = true;
                }

                return hit;
            })
            .sort();
        return getSearchResultListItems(filteredItems);
    }, [searchTerm, shops.items, foodDrink.items, getSearchResultListItems]);

    //reset search when map has been clicked
    useEffect(() => {
        setSearchTerm('');
    }, [activeFloorPlanId]);

    useEffect(() => {
        if (debouncedSearchTerm.length > 0) {
            trackSearch(debouncedSearchTerm);
        }
    }, [debouncedSearchTerm, trackSearch]);

    const updateSearchInput = (btn: string) => {
        if (btn === '{toggleMode}') {
            setMode(prevState => (prevState === 'letters' ? 'digits' : 'letters'));
            return;
        }

        if (btn === '{bksp}') {
            setSearchTerm(prevState => prevState.slice(0, -1));
            return;
        }

        if (btn === '{space}') {
            setSearchTerm(prevState => prevState + ' ');
            return;
        }

        setSearchTerm(prevState => prevState + btn);
    };

    return (
        <>
            <div
                data-tracking="clear-search-btn"
                onClick={() => setSearchTerm('')}
                className="absolute right-3 top-[5px] w-12 cursor-pointer"
            >
                {searchTerm.length > 0 ? <Close fill="none" /> : <SearchIcon fill="none" />}
            </div>
            <input
                type="text"
                value={searchTerm}
                data-tracking="search-input"
                placeholder={getTranslation('Search')}
                onChange={({ target }) => setSearchTerm(target.value)}
                className="mx-4 rounded border border-gray-600 p-4 px-6 text-lg focus:outline-gray-900"
            />

            <div
                className={clsx('scrollbar-show m-2 mx-4 mb-0 flex min-h-0 flex-[1_1_100%] flex-col gap-2 overflow-y-auto px-2 py-1', {
                    invisible: !searchTerm,
                })}
            >
                {filteredItems.length > 0 ? (
                    filteredItems.map((item, index) => (
                        <ListItemPlace
                            key={index}
                            item={item}
                            onClick={(floorplanId?: string) => {
                                onClick(floorplanId);
                                trackSelectSearch(CATEGORY_RIGHT_SEARCH_ICON, ELEMENT_RIGHT_SEARCH_ICON, item.name, LOCATION_RIGHT_PANEL);
                            }}
                        />
                    ))
                ) : (
                    <button data-component="list-item-place" className="list-item-place justify-center">
                        <p className="line-clamp-1 font-bold">{getTranslation('No Search Results')}</p>
                    </button>
                )}
            </div>

            <Keyboard onKeyPress={updateSearchInput} mode={mode} />
        </>
    );
};
